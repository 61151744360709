import React, { useEffect, useRef, useState } from 'react'
import classes from './Main.module.css'
import Wrapper from '../../components/Wrapper/Wrapper'
import NavBar from '../../components/NavBar/NavBar'
import firstScreen_main from '../../images/firstScreen-main.jpg'
import firstScreen_sub from '../../images/firstScreen-sub.jpg'
import about_main from '../../images/about-main.jpg'
import { Button } from '../../components/Button/Button'
import Icons from '../../components/Icons/Icons'
import { black, white1, white3, windowWidthMobile, windowWidthMobile2, windowWidthTablet } from '../../utils/consts'
import H1 from '../../components/H1/H1'
import { ReactComponent as LogoTitle } from '../../assets/logo-title.svg'
import { ReactComponent as LogoSubtitle } from '../../assets/logo-subtitle.svg'
import { useInView } from 'react-intersection-observer'
import Modal from '../../components/Modal/Modal'

const Main = () => {
  const firstScreenSectionRef = useRef(null)
  const aboutSectionRef = useRef(null)
  const aboutTitleRef = useRef(null)
  const aboutPhotoRef = useRef(null)
  const aboutDescriptionBlock4IconRef = useRef(null)
  const servicesDescription3Block1Ref = useRef(null)
  const servicesDescription3Block2Ref = useRef(null)
  const servicesCallToActionIconRef = useRef(null)
  const [modalIsActive, setModalIsActive] = useState(false)
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)
  const [isWindowWidthMobile2, setIsWindowWidthMobile2] = useState(window.innerWidth <= windowWidthMobile2)
  const mainRef = useRef(null)

  const [ firstScreenButtonRef, firstScreenButtonInView, firstScreenButtonEntry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1000,
  })
  const [ aboutDescriptionBlock1Ref, aboutDescriptionBlock1InView, aboutDescriptionBlock1Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1000,
  })
  const [ aboutDescriptionBlock2Ref, aboutDescriptionBlock2InView, aboutDescriptionBlock2Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 2000,
  })
  const [ aboutDescriptionBlock3Ref, aboutDescriptionBlock3InView, aboutDescriptionBlock3Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 3000,
  })
  const [ aboutDescriptionBlock4Ref, aboutDescriptionBlock4InView ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 4000,
  })
  const [ aboutDescriptionBlock5Ref, aboutDescriptionBlock5InView, aboutDescriptionBlock5Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 4000,
  })
  const [ servicesDescription1Ref, servicesDescription1InView, servicesDescription1Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1000,
  })
  const [ servicesDescription2Ref, servicesDescription2InView, servicesDescription2Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 2000,
  })
  const [ servicesDescription3Ref, servicesDescription3InView, servicesDescription3Entry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 3000,
  })
  const [ servicesRequestButtonRef, servicesRequestButtonInView, servicesRequestButtonEntry ] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1000,
  })

  window.onscroll = () => {
    const positionY = window.scrollY
    if (positionY == 0) {
      aboutSectionRef.current.style.boxShadow = `0px 0px 50px 50px ${white1}`
    }
    if (positionY > 50 && positionY <= 800) {
      let temp = (positionY-50)/400
      aboutSectionRef.current.style.boxShadow = `0px -${(positionY - 50)/20}px ${positionY}px ${(positionY/1.3) * (temp > 1 ? (positionY-50)/400 : 1)}px ${white1}`
      firstScreenSectionRef.current.style.opacity = '1'
    }
    else if (positionY > 800) {
      aboutSectionRef.current.style.boxShadow = `0px 0px 50px 50px ${white1}`
      firstScreenSectionRef.current.style.opacity = '0'
    }
  }

  const existVerticalScroll = () => {
    return mainRef.current.offsetHeight > window.innerHeight
  }

  const getBodyScrollTop = () => {
    return document.scrollY || (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop);
  }

  useEffect(() => {
    if (modalIsActive) {
      mainRef.current.dataset.scrollY = getBodyScrollTop()
      if (existVerticalScroll()) {
        mainRef.current.classList.add('body-lock')
        mainRef.current.style.top = `-${mainRef.current.dataset.scrollY}px`
      }
    }
    else if (!modalIsActive) {
      if (existVerticalScroll()) {
        mainRef.current.classList.remove('body-lock')
        window.scrollTo(0, mainRef.current.dataset.scrollY)
      }
    }
  }, [modalIsActive])

  useEffect(() => {
    if (firstScreenButtonInView) {
      setTimeout(() => {
        firstScreenButtonEntry.target.classList.add(`${classes['firstScreen-callToAction-button__animate']}`)
      }, 1000)
    }
  }, [firstScreenButtonInView])

  useEffect(() => {
    if (aboutDescriptionBlock2InView) {
      aboutDescriptionBlock1Entry.target.classList.add(`${classes['about-content-description-item-text__colorChange']}`)
    }
    if (aboutDescriptionBlock3InView) {
      aboutDescriptionBlock2Entry.target.classList.add(`${classes['about-content-description-item-text__colorChange']}`)
    }
    if (aboutDescriptionBlock4InView) {
      aboutDescriptionBlock3Entry.target.classList.add(`${classes['about-content-description-item-text__colorChange']}`)
    }
    if (aboutDescriptionBlock5InView) {
      aboutDescriptionBlock4IconRef.current.classList.add(`${classes['about-content-description-item-icon__move']}`)
      setTimeout(() => {
        aboutDescriptionBlock5Entry.target.classList.add(`${classes['about-content-description-item-text__colorChange']}`)
      }, 2000)
      setTimeout(() => {
        aboutPhotoRef.current.classList.add(`${classes['about-content-photo__slideDown']}`)
      }, 4000)
    }
  }, [aboutDescriptionBlock1InView, aboutDescriptionBlock2InView, aboutDescriptionBlock3InView, aboutDescriptionBlock4InView, aboutDescriptionBlock5InView])

  useEffect(() => {
    if (servicesDescription2InView) {
      servicesDescription1Entry.target.classList.add(`${classes['services-item__animate']}`)
      setTimeout(() => {
        servicesDescription2Entry.target.classList.add(`${classes['services-item__animate']}`)
      }, 1500)
    }
    if (servicesDescription3InView) {
      servicesDescription3Block1Ref.current.classList.add(`${classes['services-request-description-paragraph__colorChange']}`)
      setTimeout(() => {
        servicesDescription3Block2Ref.current.classList.add(`${classes['services-request-description-paragraph__colorChange']}`)
      }, 1000)
      setTimeout(() => {
        servicesCallToActionIconRef.current.classList.add(`${classes['services-request-callToAction-icon__move']}`)
      }, 2000)
      if (servicesRequestButtonInView) {
        setTimeout(() => {
          servicesRequestButtonEntry.target.classList.add(`${classes['services-request-button__hover']}`)
        }, 3250)
      }
    }
  }, [servicesDescription1InView, servicesDescription2InView, servicesDescription3InView, servicesRequestButtonInView])

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
    setIsWindowWidthMobile2(window.innerWidth <= windowWidthMobile2)
  }
  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  return (
    <div>
      {modalIsActive &&
        <Modal
          modalIsActive={modalIsActive}
          setModalIsActive={setModalIsActive}
        >
        </Modal>
      }
      <NavBar/>
      <Wrapper wrapperRef={mainRef}>
        <div className={`${classes['section']} ${classes['firstScreen']}`} id='firstScreen' ref={firstScreenSectionRef}>
          <div className={`${classes['firstScreen-photo']}`}>
            <img className={`${classes['firstScreen-photo-img']}`} src={firstScreen_main} alt='Корабль стоит на якоре в море'/>
          </div>
          <div className={`${classes['firstScreen-name']}`}>
            <H1 className={`${classes['firstScreen-name-title']}`}>АК Shipbrokers –</H1>
            <div className={`${classes['firstScreen-name-subTitle']}`}>это более 20-ти лет опыта во фрахтовании.</div>
            <div className={`${classes['firstScreen-name-divider']}`}></div>
          </div>
          <div className={`${classes['firstScreen-description']}`}>
            <div className={`${classes['firstScreen-description-item']}`}>
              <Icons
                className={`${classes['firstScreen-description-item-icon']}`}
                name='direction'
                fill={white3}
              />
              <div className={`${classes['firstScreen-description-item-text']}`}>
                Обширная база знаний в морских грузоперевозках
              </div>
            </div>
            <div className={`${classes['firstScreen-description-item']}`}>
              <Icons
                className={`${classes['firstScreen-description-item-icon']}`}
                name='direction'
                fill={white3}
              />
              <div className={`${classes['firstScreen-description-item-text']}`}>
                Топ-Брокер в сфере судоходства в Азово-Черноморском регионе
              </div>
            </div>
            <div className={`${classes['firstScreen-description-item']}`}>
              <Icons
                className={`${classes['firstScreen-description-item-icon']}`}
                name='direction'
                fill={white3}
              />
              <div className={`${classes['firstScreen-description-item-text']}`}>
                Индивидуальный подход к каждому клиенту
              </div>
            </div>
            <div className={`${classes['firstScreen-description-item']}`}>
              <Icons
                className={`${classes['firstScreen-description-item-icon']}`}
                name='direction'
                fill={white3}
              />
              <div className={`${classes['firstScreen-description-item-text']}`}>
                Сопровождение на всех этапах сотрудничества
              </div>
            </div>
          </div>
          <div className={`${classes['firstScreen-callToAction']}`}>
            <div className={`${classes['firstScreen-callToAction-title']}`}>
              Есть вопросы?
            </div>
            <Button
              buttonRef={firstScreenButtonRef}
              className={`${classes['firstScreen-callToAction-button']}`}
              type='primary'
              onClick={() => setModalIsActive(true)}
            >
              Напишите нам
            </Button>
          </div>
          <div className={`${classes['firstScreen-subImage']}`}>
            <img className={`${classes['firstScreen-subImage-img']}`} src={firstScreen_sub} alt='Корабль стоит у причала'/>
          </div>
        </div>
        <div className={`${classes['section']} ${classes['about']}`} id='about' ref={aboutSectionRef}>
          <div className={`${classes['about-divider']}`}></div>
          <div className={`${classes['about-content']}`}>
            <div className={`${classes['about-content-photo']}`} ref={aboutPhotoRef}>
              <H1 className={`${classes['about-title']} ${classes['title']}`} h1Ref={aboutTitleRef}>О нас</H1>
              <img className={`${classes['about-content-photo-img']}`} src={about_main} alt='Корабль плывёт в море'/>
            </div>
            <div className={`${classes['about-content-description']}`}>
              <div className={`${classes['about-content-description-item']}`}>
                <div className={`${classes['about-content-description-item-container']}`}>
                  <Icons
                    className={`${classes['about-content-description-item-icon']}`}
                    name='direction'
                    fill={white3}
                  />
                  <div className={`${classes['about-content-description-item-text']}`} ref={aboutDescriptionBlock1Ref}>
                    <p className={`${classes['about-content-description-item-text-paragraph']}`}>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>За плечами нашей команды более 20 лет опыта. За это время нам удалось обрести ключевые знания </span>
                      <span className={`${classes['about-content-description-item-text-paragraph__bold']}`}>о всех видах перевозок морским транспортом</span>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>, осуществляющихся по акватории земного шара.</span>
                    </p>
                    <p className={`${classes['about-content-description-item-text-paragraph']}`}>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>А в 2011 году мы основали АК Shipbrokers — топовую компанию на рынке </span>
                      <span className={`${classes['about-content-description-item-text-paragraph__bold']}`}>международных морских грузоперевозок.</span>
                    </p>
                  </div>
                </div>
                <div className={`${classes['about-content-description-item-divider']}`}></div>
              </div>
              <div className={`${classes['about-content-description-item']}`}>
                <div className={`${classes['about-content-description-item-container']}`}>
                  <Icons
                    className={`${classes['about-content-description-item-icon']}`}
                    name='direction'
                    fill={white3}
                  />
                  <div className={`${classes['about-content-description-item-text']}`} ref={aboutDescriptionBlock2Ref}>
                    <p className={`${classes['about-content-description-item-text-paragraph']}`}>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>С нами вы можете быть уверены — </span>
                      <span className={`${classes['about-content-description-item-text-paragraph__bold']}`}>перевезти груз по морю </span>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>из любой точки станет возможным с наиболее выгодными для вас условиями: по объёмам, времени, средствам — и любым критериям, которые будут вас интересовать.</span>
                      </p>
                  </div>
                </div>
                <div className={`${classes['about-content-description-item-divider']}`}></div>
              </div>
              <div className={`${classes['about-content-description-item']}`}>
                <div className={`${classes['about-content-description-item-container']}`}>
                  <Icons
                    className={`${classes['about-content-description-item-icon']}`}
                    name='direction'
                    fill={white3}
                  />
                  <div className={`${classes['about-content-description-item-text']}`} ref={aboutDescriptionBlock3Ref}>
                    <p className={`${classes['about-content-description-item-text-paragraph']}`}>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>Переходите в раздел Контакты, чтобы </span>
                      <span className={`${classes['about-content-description-item-text-paragraph__bold']}`}>рассчитать стоимость морской перевозки груза. </span>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>Или переходите в Услуги, чтобы познакомиться с нами подробнее.
                      </span>
                    </p>
                  </div>
                </div>
                <div className={`${classes['about-content-description-item-divider']}`}></div>
              </div>
              <div className={`${classes['about-content-description-item']}`} ref={aboutDescriptionBlock4Ref}>
                <div className={`${classes['about-content-description-item-container']}`}>
                  <Icons
                    className={`${classes['about-content-description-item-icon']}`}
                    name='direction'
                    fill={black}
                    iconRef={aboutDescriptionBlock4IconRef}
                  />
                  <div className={`${classes['about-content-description-item-text']} ${classes['about-content-description-item-text__noAnimation']}`}>
                    <p className={`${classes['about-content-description-item-text-paragraph']} ${classes['about-content-description-item-text-paragraph__bold']}`}>Важно: накопленный опыт и экспертиза в морских перевозках помогла нашему сервису выйти за рамки стандартного понимания брокерского бизнеса. Мы даём подробные консультации в смежных сферах — сюрвей, торговые контракты и другие финансовые вопросы.</p>
                  </div>
                </div>
                <div className={`${classes['about-content-description-item-divider']}`}></div>
              </div>
              <div className={`${classes['about-content-description-item']}`}>
                <div className={`${classes['about-content-description-item-container']}`}>
                  <Icons
                    className={`${classes['about-content-description-item-icon']}`}
                    name='direction'
                    fill={white3}
                  />
                  <div className={`${classes['about-content-description-item-text']}`} ref={aboutDescriptionBlock5Ref}>
                    <p className={`${classes['about-content-description-item-text-paragraph']}`}>
                      <span className={`${classes['about-content-description-item-text-paragraph__default']}`}>
                        Мы успешно работаем с основными договорами морской перевозки сухих грузов: экспорт, импорт, правила, регулирующие внешнеэкономическую деятельность, валютный и таможенный контроль. Понимая принципы торговли по GAFTA, FOSFA, мы правильно выстраиваем договоры морской перевозки.
                      </span>
                    </p>
                  </div>
                </div>
                <div className={`${classes['about-content-description-item-divider']}`}></div>
              </div>
            </div>
          </div>
          <div className={`${classes['about-divider']} ${classes['about-divider__down']}`}></div>
        </div>
        <div className={`${classes['section']} ${classes['services']}`} id='services'>
          <H1 className={`${classes['services-title']} ${classes['title']}`}>Наши услуги</H1>
          <div className={`${classes['services-container']}`}>
            <div className={`${classes['services-item']}`} ref={servicesDescription1Ref}>
              <Icons
                className={`${classes['services-item-icon']}`}
                name='direction'
                fill={white1}
              />
              <div className={`${classes['services-item-text']}`}>
                <p className={`${classes['services-item-text-paragraph']}`}><span className={`${classes['about-content-description-item-text-paragraph__bold']}`}>Фрахтование судов под сухие грузы</span> любых видов</p>
              </div>
            </div>
            <div className={`${classes['services-item']}`} ref={servicesDescription2Ref}>
              <Icons
                className={`${classes['services-item-icon']}`}
                name='direction'
                fill={white1}
              />
              <div className={`${classes['services-item-text']}`}>
                <p className={`${classes['services-item-text-paragraph']}`}><span className={`${classes['about-content-description-item-text-paragraph__bold']}`}>Фрахтование судов-рефрижераторов</span> <br/>для перевозки скоропортящихся грузов.</p>
              </div>
            </div>
          </div>
          <div className={`${classes['services-container']} ${classes['services-container-fullWidth']}`}>
            <div className={`${classes['services-request']}`} ref={servicesDescription3Ref}>
              <div className={`${classes['services-request-description']}`}>
                <p className={`${classes['services-request-description-paragraph']}`} ref={servicesDescription3Block1Ref}>
                  В процессе работы с заказчиком, мы обращаем внимание На все нюансы и <span className={`${classes['services-request-description-paragraph__bold']}`}>подробно объясняем заказчику процессы, правила и важные аспекты морских грузоперевозок.</span>
                </p>
                <p className={`${classes['services-request-description-paragraph__bold']}`} ref={servicesDescription3Block2Ref}>
                  Подходим к каждой заявке в индивидуальном порядке.
                </p>
              </div>
              <div className={`${classes['services-request-callToAction']}`}>
                <Icons
                  className={`${classes['services-request-callToAction-icon']}`}
                  name='direction'
                  fill={white1}
                  iconRef={servicesCallToActionIconRef}
                />
                <p className={`${classes['services-request-callToAction-paragraph']}`}>
                  Получите отчёт о состоянии рынка прямо сейчас <span className={`${classes['services-request-description-paragraph__bold']}`}> — оставьте свои контакты и мы вышлем вам документ на email.</span>
                </p>
              </div>
              <Button
                className={`${classes['services-request-button']}`}
                buttonRef={servicesRequestButtonRef}
                type='secondary'
                onClick={() => setModalIsActive(true)}
              >
                Оставить контакт
              </Button>
            </div>
          </div>
        </div>
        <div className={`${classes['section']} ${classes['contact']}`} id='contact'>
          <H1 className={`${classes['contact-title']} ${classes['title']}`}>Контакты</H1>
          <div className={`${classes['contact-logo-container']}`}>
            <LogoTitle className={`${classes['contact-logo']}`}/>
            <div className={classes['contact-logo-divider']}></div>
            <LogoSubtitle className={`${classes['contact-logo-sub']}`}/>
          </div>
          <div className={`${classes['contact-description']}`}>
            <div className={`${classes['contact-description-container']}`}>  
              <div className={`${classes['contact-address']} ${classes['contact-description-item']}`}>
                <div className={`${classes['contact-address-title']} ${classes['contact-description-item-title']}`}>Наш адрес:</div>
                <div className={`${classes['contact-address-content']} ${classes['contact-description-item-content']}`}>353900, Россия, Краснодарский край <br/> Новороссийск, ул. Советов 40, офис 9</div>
              </div>
              <div className={`${classes['contact-phoneNumber']} ${classes['contact-description-item']}`}>
                <div className={`${classes['contact-phoneNumber-title']} ${classes['contact-description-item-title']}`}>Позвоните нам:</div>
                <div className={`${classes['contact-phoneNumber-content']} ${classes['contact-description-item-content']}`}>+7(8617)729323</div>
              </div>
              <div className={`${classes['contact-email']} ${classes['contact-description-item']}`}>
                <div className={`${classes['contact-email-title']} ${classes['contact-description-item-title']}`}>Или напишите на электронную почту:</div>
                <div className={`${classes['contact-email-content']} ${classes['contact-description-item-content']}`}>fix@akshipbrokers.com</div>
              </div>
            </div>
          </div>
          <div className={`${classes['contact-map']}`}>
          <iframe
            className={`${classes['contact-map-source']}`}
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A786ff35c1478c41e6161a49991412ef078f56ca7c907dfd94d4afb3f7dd3f841&amp;source=constructor"
            width="100%"
            frameborder='0'
          ></iframe>
          </div>
          {isWindowWidthMobile &&
            <div className={`${classes['toTheTop']}`}>
              <div className={`${classes['toTheTop-container']}`} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                <Icons
                  name='arrow-up'
                  fill={white1}
                  className={`${classes['toTheTop-icon']}`}
                />
              </div>
            </div>
          }
        </div>
      </Wrapper>
    </div>
  )
}

export default Main