import React, { useEffect, useState } from 'react';
import classes from './NavBar.module.css';
import { ReactComponent as Flag } from '../../assets/flag.svg'
import { ReactComponent as LogoTitle } from '../../assets/logo-title.svg'
import { ReactComponent as LogoSubtitle } from '../../assets/logo-subtitle.svg'
import { HashLink } from 'react-router-hash-link';
import { MAIN_ROUTE, windowWidthMobile, windowWidthMobile2, windowWidthTablet } from '../../utils/consts';
import Icons from '../Icons/Icons';

const NavBar = () => {
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)
  const [isWindowWidthMobile2, setIsWindowWidthMobile2] = useState(window.innerWidth <= windowWidthMobile2)
  const [menuPopupIsActive, setMenuPopupIsActive] = useState(false)

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = -120; 
    window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'}); 
  }

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
    setIsWindowWidthMobile2(window.innerWidth <= windowWidthMobile2)
  }
  useEffect(() => {
    window.addEventListener('resize', onWindowWidthChange);
    return () => window.removeEventListener('resize', onWindowWidthChange);
  }, [])

  return (
    <nav className={classes['bar']}>
      <div className={classes['content']}>
        <div className={classes['menu']}>
          <div className={`${classes['logo']}`}>
            <LogoTitle className={`${classes['logo-title']}`}/>
            <div className={classes['logo-divider']}></div>
            <LogoSubtitle className={`${classes['logo-subtitle']}`}/>
          </div>
        </div>
        <div className={classes['menu']}>
          {isWindowWidthMobile
          ?
            <div
              className={classes['links-burger-container']}
              onClick={() => setMenuPopupIsActive((menuPopupIsActive) => !menuPopupIsActive)}
            >
              {menuPopupIsActive
              ?
                <Icons
                  name='x'
                  className={`${classes['links-burger']} ${classes['links-burger__close']}`}
                />
              : 
                <Icons
                  name='burger'
                  className={`${classes['links-burger']}`}
                />
              }
            </div>
          :
            <div className={classes['links']}>
              <HashLink className={classes['link']} to={`${MAIN_ROUTE}#about`} scroll={el => scrollWithOffset(el)}>О НАС</HashLink>
              <HashLink className={classes['link']} to={`${MAIN_ROUTE}#contact`} smooth>КОНТАКТЫ</HashLink>
            </div>
          }
          {!isWindowWidthMobile &&
            <Flag/>
          }
          {isWindowWidthMobile && menuPopupIsActive &&
            <div className={`${classes['menu-popup']}`}>
              <div className={`${classes['menu-popup-links']}`}>
                <HashLink className={classes['link']} to={`${MAIN_ROUTE}#about`} scroll={el => scrollWithOffset(el)} onClick={() => setMenuPopupIsActive(false)}>О НАС</HashLink>
                <HashLink className={classes['link']} to={`${MAIN_ROUTE}#contact`} smooth onClick={() => setMenuPopupIsActive(false)}>КОНТАКТЫ</HashLink>
              </div>
            </div>
          }
        </div>
      </div>
    </nav>
  )
}

export default NavBar;